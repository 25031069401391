import React,{useState, useEffect} from 'react';   
import '../App.css'; 
import axios from '../../node_modules/axios'; 
import SideMenuHam from './SideMenu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import {ModalCarga, Toast} from './modalCarga'; 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
import { ThreeDots } from  'react-loader-spinner'
import customStyles from './modalCustomStyles';

const Login = (isLoggedIna) =>  {
	const [userdptoid, setUserdptoid] = useState("null");
	const [defaultLogo, setDefaultLogo] = useState("null");
	const [defaultColor, setDefaultColor] = useState("null");
	const [defUser, setDefUser] = useState("");
	const [defPass, setDefPass] = useState("");
	const [defIdOrden, setDefIdOrden] = useState("");
	const [defIdRequisicion, setDefIdRequisicion] = useState("");
	
	const [usuario, setUsuario] = useState("null");
	const [userid, setUserid] = useState("null");
	const [name, setName] = useState("null");
	const [listadepartamento, setListadepartamento] =  useState([]); 
	const [dptos, setDeptos] = useState([]);  
	const [departamento, setDepartamento] = useState("null");
	const [iddepto, setidDepto] = useState("null");
	const [tipo, setTipo] = useState("null");
	const [isLoggedIn, setisLoggedIn] = useState(isLoggedIna);
	const [rzonsocial, setRzonsocial] = useState([]);
	const [superus, setSuperUS] = useState([]);
	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	const [modulos, setModulos] = useState([]);
	const [restableceoc, setRestableceoc] = useState([]); 
	


	const [unidades, setUnidades] = React.useState([]);

	window.event = new Event('event');

	window.addEventListener('event', function(info) {
	  
	if(String(info.detail.user) != "" && info.detail.pass != ""    ){
	 setDefUser(info.detail.user);
	 setDefPass(info.detail.pass);
	 setDefIdOrden(info.detail.idorden);
	 setDefIdRequisicion(info.detail.idrequisicion);
	  
	 
	 
	 } 
	 
	}, false);

    function openModalLoad() { 
		setIsOpenLoad(true); 
		 }  
	   
		 function closeModalLoad() { 
		setIsOpenLoad(false); 
		 }
	
	useEffect(()=> {
		getDepartamentos();
		getDefault(); 
	}, [])
	
	function notify(message){
        toast(message);
    }
	function handleKeyPress  (event) {
		if(event.key == 'Enter'){ 
			Login(event);
		}
	}
 
	 

	async function getDefault(){
		var id = "defaultLogo";
		openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);
		//console.log(rese.data); 
	 	closeModalLoad(); 
		setDefaultLogo(rese.data[0].default_logo);  
		setDefaultColor(rese.data[0].default_color);  
	} 
	async function getDepartamentos(){
		var id = "getDepartamentos";
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		setDeptos(rese.data);   
	}

	async function Login(e){  
		
		e.preventDefault();  
		var select = document.getElementById("select"); 
		setListadepartamento(select.value);
		openModalLoad(); 
		var user = document.getElementById("form-usuario").value;
		var pass = document.getElementById("form-password").value; 
		var id = "Login";  
		let fd = new FormData()  
		fd.append("user", user)
		fd.append("pass", pass)
		fd.append("id", id); 
		fd.append("select", select.value);  
		fd.append("rzonsocial", select.options[select.selectedIndex].text);  
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
		closeModalLoad(); 
		if(res.data[0].res == "1"){

			if (window.Android){
				window.Android.showToast(user, pass, res.data[0].userid, "1");
				}
			
			setUserdptoid(res.data[0].userdptoid);
			setUsuario(res.data[0].usuario); 
			setUserid(res.data[0].userid);
			setName(res.data[0].name);
			setDepartamento(res.data[0].departamento);
			setidDepto(res.data[0].iddepto);
			setSuperUS(res.data[0].superus);
			setTipo(res.data[0].tipo); 
			setRzonsocial(select.options[select.selectedIndex].text);
			setUnidades(res.data[1]);
			setRestableceoc(res.data[0].restableceoc);
			
			if(res.data.length > 2){
				setModulos(res.data[2]);	
			}
			setisLoggedIn(false); 
			
			 

		} else {
			notify("Datos de acceso incorrectos");
		}  
	}

	 
    if(isLoggedIn){
		return( 
			<div> 
				 <style> 
            </style>
				<div id="body-content">
					<div id="div-img" style={{backgroundColor: defaultColor}}>
						<img alt="Logo" src={process.env.REACT_APP_API_URL_DEFAULT+defaultLogo} style={{width:'-webkit-fill-available'}}></img>
					</div>  
					<div id="div-form">
						<h1>Compras</h1>
						
						<span>Usuario</span>
						<input id="form-usuario" defaultValue={defUser} onKeyPress={handleKeyPress}  type="text" style={{height:'30px',width:'100%'}} placeholder="Usuario"/>
						<span>Contraseña</span>
						<input id="form-password" defaultValue={defPass} onKeyPress={handleKeyPress}  type="password" style={{height:'30px',width:'100%'}}  placeholder="Contraseña"/>
						<span>Seleccione</span>
						<select style={{width:'100%'}} id="select">
							{ 
								dptos.map(item => ( 
									<option value={item.dptoid}> {item.name}</option>
								))
							}  
						</select>    
						<button id="form-btn" style={{backgroundColor:defaultColor, color:'white'}} onClick={(e) => Login(e)}>INICIAR SESIÓN</button> 
					</div>
				</div> 
				 
			<ModalCarga modalIsOpenLoad={modalIsOpenLoad}  defaultColor={defaultColor} closeModalLoad={closeModalLoad}/>    

			<Toast/>    
			</div>
		); 
	} else {
		return (
			<div >
				<SideMenuHam restableceoc={restableceoc} defIdOrden={defIdOrden} defIdRequisicion={defIdRequisicion} modulos={modulos} unidades={unidades} superus={superus} defaultColor={defaultColor} defaultLogo={defaultLogo} userdptoid={userdptoid} departamentoid={iddepto} dptoid={listadepartamento}  departamento={departamento} usuario={usuario} userid={userid} name={name}  selected='2' tipo={tipo} rzonsocial={rzonsocial}/>
			</div>
		);
	}
}

export  default Login;