import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import './App.css';
import { Nabvar } from "./component/Navbar"; 
import { FaTrash } from 'react-icons/fa'; 
import Modal from 'react-modal';
import {ModalCarga, Toast} from './component/modalCarga'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   
import customStyles from './component/modalCustomStyles';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
import tableCustomStyles from './tableCustomStyles';
function Config(props){
 
const columnsUnidades = [

	{
        name: 'Folio', 
        sortable: true,  
        selector: row => row.id,
        
    }, 
    {
        name: 'Unidad',   
        cell: (row) => {
            return (
                <td> <input 
				defaultValue={row.unidad}
					onChange={(e)=> {
					document.getElementById("actualizarUnidad"+row.id).value(e.target.value)}}
					class="width-100" id={"actualizarUnidad"+row.id} ></input></td> 
            )
        }
    },
    {
        name: 'Actualizar', 
        cell: (row) => {
            return (
                <td><button   className='btn btn-outline-success btn-sm' onClick={() => actualizarUnidad(row.id)}>Actualizar</button></td>
            )
        }
    },
    {
        name: 'Eliminar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarUnidad(row.id)}>Eliminar</button></td>
            )
        }
    }
];

const columnsCategorias = [

	{
        name: 'Folio', 
        sortable: true,  
        selector: row => row.id,
        
    }, 
    {
        name: 'Categoría',   
        cell: (row) => {
            return (
                <td> <input 
				defaultValue={row.categoria}
					onChange={(e)=> {
					document.getElementById("actualizarCategoria"+row.id).value(e.target.value)}}
					class="width-100" id={"actualizarCategoria"+row.id} ></input></td> 
            )
        }
    },
    {
        name: 'Actualizar', 
        cell: (row) => {
            return (
                <td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarCategoria(row.id)}>Actualizar</button></td>
            )
        }
    },
    {
        name: 'Eliminar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarCategoria(row.id)}>Eliminar</button></td>
            )
        }
    }
];


const columnsDepartamentos = [

	{
        name: 'Folio', 
        sortable: true,  
        selector: row => row.dptoid,
		width: "90px",
        
    }, 
    {
        name: 'Departamento',   
        cell: (row) => {
            return (
                /*<td> <input 
				defaultValue={row.name}
					onChange={(e)=> {
					document.getElementById("nuevonombredepartamento"+row.dptoid).value(e.target.value)}}
					class="width-100" id={"nuevonombredepartamento"+row.dptoid} ></input></td> */
					 
					  <td> <input 
					  value={row.name}
						  onKeyDown={(e)=> {
						  document.getElementById("nuevonombredepartamento"+row.dptoid).value = e.target.value}}
						  class="width-100" id={"nuevonombredepartamento"+row.dptoid} ></input></td> 
            )
        }
    },
    {
        name: 'Encargado (Realiza)', 
        cell: (row) => {
            return (
                <td><select id={"slc-realiza-"+row.dptoid} class="select-req-1">
							{(row.useridrealiza != null) ? 
							<option value={row.useridrealiza}>{row.nombrerealiza}</option>
							:
							<></>
							}	
						
											{ props.usuarios.map(row => ( 
											<option value={row.userid}>{row.name}</option>    
											))}  
										</select> 	
						</td> 
            )
        }
    },
    {
        name: 'Encargado (Revisa)',   
        cell: (row) => {
            return (
				<td>
						<select id={"slc-encargado-"+row.dptoid} class="select-req-1">
							{(row.useridencargado != null) ? 
							<option value={row.useridencargado}>{row.nombreencargado}</option>
							:
							<></>
							}	
						
											{ props.usuarios.map(row => ( 
											<option value={row.userid}> {row.name}</option>    
											))}  
										</select> 	</td> 
            )
        }
    },
    {
        name: 'Actualizar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarDatosDepartamento(row.dptoid, row.name)}>Actualizar</button></td>
            )
        }
    },
    {
        name: 'Autorizar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalAutoriza(row.name, row.dptoid)}>Ver / agregar</button></td>
            )
        }
    },
    {
        name: 'Acceso',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalAccesoaDpto(row.name, row.dptoid)}>Ver / agregar</button></td>
            )
        }
    },
    {
        name: 'Notificar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalNotificarDpto(row.name, row.dptoid)}>Ver / agregar</button></td>
            )
        }
    },
    {
        name: 'Eliminar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarDepartamento(row.dptoid, row.name)}>Eliminar</button></td>
            )
        }
    }
];

const columnsBancos = [

	{
        name: 'Folio', 
        sortable: true,  
        selector: row => row.folio,
        
    }, 
    {
        name: 'Banco',   
        cell: (row) => {
            return (
                <td><input 
				defaultValue={row.nombrebanco}

					onChange={(e)=> {
					document.getElementById("nombrebanco"+row.folio).value(e.target.value)}}
					id={"nombrebanco"+row.folio} ></input></td> 
            )
        }
    },
    {
        name: 'Tarjetas', 
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalTarjetas(row.nombrebanco, row.folio)}>Ver / agregar</button></td>
            )
        }
    },
    {
        name: 'Actualizar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarNombreBanco(row.folio, row.nombrebanco)}>Actualizar</button></td>
            )
        }
    },
    {
        name: 'Eliminar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarBanco(row.folio, row.nombrebanco)}>Eliminar</button></td>
            )
        }
    }
];


const columnsEmpresas = [

	{
        name: 'Razón Social', 
        sortable: true,  
        selector: row => row.name,
        
    }, 
    {
        name: 'RFC',   
        sortable: true,  
        selector: row => row.rfc,
    },
    {
        name: 'Diorección', 
		sortable: true,  
        selector: row => row.direccion,
    },
    {
        name: 'Teléfono',   
        cell: (row) => {
            return (
				<td><input 
				defaultValue={row.telefono}
					onChange={(e)=> {
					document.getElementById("telefonoempresa"+row.dptoid).value(e.target.value)}}
					id={"telefonoempresa"+row.dptoid} class="width-100-px"></input></td>
            )
        }
    },
    {
        name: 'Logo',   
        cell: (row) => {
            return (
				
					(row.logo != null) ?
					<>
					<td class="width-100-px" align="center"><a href={process.env.REACT_APP_API_URL_IMG+row.logo} target="_blank">Ver</a>
					<span>&nbsp; &nbsp; </span>
					<FaTrash class="color-red" onClick={() => removerLogo(row.dptoid)}/></td>
					</>
					:
					<td><button  className='btn btn-outline-success btn-sm' onClick={() => addLogo(row.dptoid)}>Agregar</button></td>
				
            )
        }
    },
    {
        name: 'Actualizar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarDatosEmpresa(row.dptoid, row.name)}>Actualizar</button></td>
            )
        }
    },
    {
        name: 'Eliminar',   
        cell: (row) => {
            return (
				<td><button  className='btn btn-outline-success btn-sm' onClick={() => eliminarEmpresa(row.dptoid, row.name)}>Eliminar</button></td>
            )
        }
    }
];
  
	const [dptos2, setDeptos2] = useState([]);  
	const [categorias, setCategorias] = useState([]);  
	const [selectedEmpresa, setSelectedEmpresa] = useState([]);    
	useEffect(()=> { 
		getDepartamentos2();
		 
	}, [])

	 
	function notify(message){
        toast(message);
    }
 

	 

	function addLogo(dptoid){
		setSelectedEmpresa(dptoid); 
		document.getElementById("input-cotizacion").click();
	}

	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [modalIsOpenBanco, setIsOpenBanco] = React.useState(false);
	const [modalIsOpenDepartamento, setIsOpenDepartamento] = React.useState(false);
	const [modalIsOpenCategoria, setIsOpenCategoria] = React.useState(false);
	const [modalIsOpenUnidad, setIsOpenUnidad] = React.useState(false);
	const [modalIsOpenTarjetas, setIsOpenTarjetas] = React.useState(false);
	const [modalIsOpenAutoriza, setIsOpenAutoriza] = React.useState(false);
	const [modalIsOpenAccesoaDpto, setIsOpenAccesoaDpto] = React.useState(false);
	const [modalIsOpenNotificarDpto, setIsOpenNotificarDpto] = React.useState(false);
	const [bancoSeleccionado, setBancoSeleccionado] = React.useState(false);
	const [folioBancoSeleccionado, setFolioBancoSeleccionado] = React.useState(false);
	const [tarjetas, setTarjetas] = useState([]); 
	const [departamentoSeleccionado, setDepartamentoSeleccionado] = React.useState(false);
	const [folioDepartamentoSeleccionado, setFolioDepartamentoSeleccionado] = React.useState(false);
	const [departamentoAutoriza, setDepartamentoAutoriza] = React.useState([]);
	const [departamentoAccesos, setDepartamentoAccesos] = React.useState([]);
	const [departamentoNotificar, setDepartamentoNotificar] = React.useState([]);
	 

    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
	 function openModal() { 
		setIsOpen(true); 
		 }  
   
		 function openModalBanco() { 
			setIsOpenBanco(true); 
			 }  
			 function openModalAutoriza(nombreDepartamento, idDepartamento) { 
				getAutorizaDepartamento(idDepartamento);
				setDepartamentoSeleccionado(nombreDepartamento);
				setFolioDepartamentoSeleccionado(idDepartamento);
				setIsOpenAutoriza(true); 
				
				 }  

				 function openModalAccesoaDpto(nombreDepartamento, idDepartamento) { 
					getAccesoDepartamento(idDepartamento);// get acceso al dpto
					setDepartamentoSeleccionado(nombreDepartamento);
					setFolioDepartamentoSeleccionado(idDepartamento);
					setIsOpenAccesoaDpto(true); 
					
					 }  


					 function openModalNotificarDpto(nombreDepartamento, idDepartamento) { 
						getNotificarDepartamento(idDepartamento);// get notificar dpto
						setDepartamentoSeleccionado(nombreDepartamento);
						setFolioDepartamentoSeleccionado(idDepartamento);
						setIsOpenNotificarDpto(true); 
						
						 }  
	   
			 function openModalDepartamento() { 
				setIsOpenDepartamento(true); 
				 }  
		   
				 function openModalCategoria() { 
					setIsOpenCategoria(true); 
					 }  
					 function openModalUnidad() { 
						setIsOpenUnidad(true); 
						 }  
			   
		 function openModalTarjetas(banco, folio) { 
			getTarjetas(folio);
			setBancoSeleccionado(banco); 
			setFolioBancoSeleccionado(folio); 
			setIsOpenTarjetas(true); 
			 }  
	   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }
	 function closeModalAutoriza() { 
		setIsOpenAutoriza(false); 
		 }
		 function closeModalAcceso() { 
			setIsOpenAccesoaDpto(false); 
			 }
	 

			 function closeModalNotificar() { 
				setIsOpenNotificarDpto(false); 
				 }
		 
     function closeModalBanco() { 
		setIsOpenBanco(false); 
		 }

		 function closeModalDepartamento() { 
			setIsOpenDepartamento(false); 
			 }
			 function closeModalCategoria() { 
				setIsOpenCategoria(false); 
				 }
				 function closeModalUnidad() { 
					setIsOpenUnidad(false); 
					 }
		 function closeModalTarjetas() { 
			setIsOpenTarjetas(false); 
			 }

	 function closeModal() { 
		setIsOpen(false); 
		 }
	
		 async function getAutorizaDepartamento(dptoid){
			setDepartamentoAutoriza([]);
				let fd = new FormData()  
				fd.append("id", "getAutorizaDepartamento") 
				fd.append("dptoid", dptoid) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				
				setDepartamentoAutoriza(res.data);
		 }

		 async function getAccesoDepartamento(dptoid){
			setDepartamentoAccesos([]);
				let fd = new FormData()  
				fd.append("id", "getAccesoDepartamento") 
				fd.append("dptoid", dptoid) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				
				setDepartamentoAccesos(res.data);
		 }



		 async function getNotificarDepartamento(dptoid){
			setDepartamentoNotificar([]);
				let fd = new FormData()  
				fd.append("id", "getNotificarDepartamento") 
				fd.append("dptoid", dptoid) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				
				setDepartamentoNotificar(res.data);
		 }

		 

		 async function addAutorizaDepartamento(userid){
			let fd = new FormData()  
			fd.append("id", "addAutorizaDepartamento") 
			fd.append("dptoid", folioDepartamentoSeleccionado) 
			fd.append("userid", document.getElementById("slc-agg-autoriza").value) 
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			notify(res.data);
			getAutorizaDepartamento(folioDepartamentoSeleccionado);
	 }
	 async function addAccesoDepartamento(userid){
		let fd = new FormData()  
		fd.append("id", "addAccesoDepartamento") 
		fd.append("dptoid", folioDepartamentoSeleccionado) 
		fd.append("userid", document.getElementById("slc-agg-acceso").value) 
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
		notify(res.data);
		getAccesoDepartamento(folioDepartamentoSeleccionado);
 }

 
 async function addNotificarDepartamento(userid){
	let fd = new FormData()  
	fd.append("id", "addNotificarDepartamento") 
	fd.append("dptoid", folioDepartamentoSeleccionado) 
	fd.append("userid", document.getElementById("slc-agg-notificar").value) 
	const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
	notify(res.data);
	getNotificarDepartamento(folioDepartamentoSeleccionado);
}

 

	 

		 async function actualizarColor(){
			var color = document.getElementById("defaultColor").value;
			if(window.confirm('¿Actualizar color?')){ 
				let fd = new FormData()  
				fd.append("id", "actualizarDefaultColor")
				fd.append("color", color) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				notify(res.data.trim()); 
			}
		 }
		 async function actualizarCategoria(folio){
			var categoria = document.getElementById("actualizarCategoria"+folio).value;
			if(window.confirm('¿Actualizar categoría?')){ 
				let fd = new FormData()  
				fd.append("id", "actualizarCategoria")
				fd.append("folio", folio) 
				fd.append("categoria", categoria) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				notify(res.data.trim());
				
				props.getCategorias();
			}
		 }
		 async function actualizarUnidad(folio){
			var unidad = document.getElementById("actualizarUnidad"+folio).value;
			if(window.confirm('¿Actualizar unidad?')){ 
				let fd = new FormData()  
				fd.append("id", "actualizarUnidad")
				fd.append("folio", folio) 
				fd.append("unidad", unidad) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				notify(res.data.trim());
				
				props.getUnidades();
			}
		 }

		 
		 async function eliminarCategoria(folio){ 
			if(window.confirm('¿Eliminar categoría?')){ 
				let fd = new FormData()  
				fd.append("id", "eliminarCategoria")
				fd.append("folio", folio) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				notify(res.data.trim());
				
				props.getCategorias();
			}
		 }

		 
		 async function eliminarUnidad(folio){ 
			if(window.confirm('¿Eliminar unidad?')){ 
				let fd = new FormData()  
				fd.append("id", "eliminarUnidad")
				fd.append("folio", folio) 
				const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
				notify(res.data.trim());
				
				props.getUnidades();
			}
		 }
 
 

	async function getDepartamentos2(){
    	var id = "getDepartamentoss";
		setDeptos2([]);
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		setDeptos2(rese.data);  
		//console.log(rese.data);
		var Data = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}

	async function removerLogo(dptoid){ 
        if(window.confirm('¿Quitar Logo?')){ 
            let fd = new FormData()  
            fd.append("id", "removerLogo")
            fd.append("dptoid", dptoid) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
            props.getEmpresas();
        }
	}
	async function guardarEmpresa(){ 
		let fd = new FormData() 
		fd.append("id","guardarEmpresa") 
		fd.append("rzonsocial",document.getElementById("rzonsocial").value) 
		fd.append("rfc",document.getElementById("rfc").value) 
		fd.append("direccion",document.getElementById("direccion").value) 
		fd.append("telefono", document.getElementById("telefono").value) 
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad();
		 
		notify(rese.data.trim());
		props.getEmpresas();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}
	async function guardarBanco(){ 
		let fd = new FormData() 
		fd.append("id","guardarBanco") 
		fd.append("nombrebanco",document.getElementById("nombrebanco").value)  
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad();
		 
		notify(rese.data.trim());
		props.getBancos();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}

	async function guardarDepartamento(){ 
		let fd = new FormData() 
		fd.append("id","guardarDepartamento") 
		fd.append("nombredepartamento",document.getElementById("nombredepartamento").value)  
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad();
		 
		notify(rese.data.trim());
		getDepartamentos2();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}
	
	async function guardarCategoria(){ 
		let fd = new FormData() 
		fd.append("id","guardarCategoria") 
		fd.append("categoria",document.getElementById("nombrecategoria").value)  
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad(); 
		notify(rese.data.trim());
		props.getCategorias();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}
	

	async function guardarUnidad(){ 
		let fd = new FormData() 
		fd.append("id","guardarUnidad") 
		fd.append("unidad",document.getElementById("nombreunidad").value)  
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad(); 
		notify(rese.data.trim());
		props.getUnidades();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}
	

	async function getTarjetas(folio){
		var id = "getTarjetas";
		openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&foliobanco='+folio);  
		closeModalLoad();
		
		setTarjetas(rese.data);  
	}

	async function guardarTarjeta(){ 
		let fd = new FormData() 
		fd.append("id","guardarTarjeta") 
		fd.append("notarjeta",document.getElementById("numerotarjeta").value)  
		fd.append("foliobanco",folioBancoSeleccionado)  
		openModalLoad();
		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);  
		closeModalLoad();
		document.getElementById("numerotarjeta").value = "";
		getTarjetas(folioBancoSeleccionado);
		notify(rese.data.trim());
		//getTarjetas();
		//var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}

	async function postFile2(){
		openModalLoad();
		let fd = new FormData() 
		fd.append("id", "agregarLogo")
		fd.append("dptoid", selectedEmpresa) 
		fd.append("file", document.getElementById("input-cotizacion").files[0])  
		const res = await axios.post(process.env.REACT_APP_API_URL,  fd, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}); 
		closeModalLoad();
		document.getElementById("input-cotizacion").value = "";
		//verRequisicion(document.getElementById("folio").value);
		props.getEmpresas();
		notify(res.data.trim());
		//
	}

	async function actualizarDatosEmpresa(dptoid, empresa){
        
        
        if(window.confirm('¿Quiere actualizar los datos de: ' + empresa+'?')){ 
            let fd = new FormData() 
            fd.append("id", "actualizarDatosEmpresa")
            fd.append("dptoid", dptoid) 
            fd.append("telefono", document.getElementById("telefonoempresa" + dptoid).value) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            getDepartamentos2();
            
        }
    }

	

	async function actualizarNombreBanco(bancoid, banco){
        
        
        if(window.confirm('¿Quiere actualizar los datos de: ' + banco +'?')){ 
            let fd = new FormData() 
            fd.append("id", "actualizarNombreBanco")
            fd.append("bancoid", bancoid) 
            fd.append("nombrebanco", document.getElementById("nombrebanco" + bancoid).value) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            //getDepartamentos2();
			props.getBancos();
            
        }
    }

	

	async function actualizarDatosDepartamento(dptoid, departamento){
        
        
        if(window.confirm('¿Quiere actualizar los datos de: ' + departamento +'?')){ 
            let fd = new FormData() 
            fd.append("id", "actualizarDatosDepartamento")
            fd.append("dptoid", dptoid) 
            fd.append("realiza", document.getElementById("slc-realiza-"+dptoid).value) 
            fd.append("encargado", document.getElementById("slc-encargado-"+dptoid).value) 
            fd.append("nuevonombredepartamento", document.getElementById("nuevonombredepartamento" + dptoid).value) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            props.getEmpresas();
            
        }
    }


 
	async function eliminarEmpresa(dptoid, empresa){
        
        
        if(window.confirm('¿Eliminar : ' + empresa+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarEmpresa")
            fd.append("dptoid", dptoid)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            props.getEmpresas();
            
        }
    }

	async function eliminarBanco(folio, banco){
        
        
        if(window.confirm('¿Eliminar: ' + banco+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarBanco")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            props.getBancos();
            
        }
    }

	async function eliminarDepartamento(folio, departamento){
        
        
        if(window.confirm('¿Eliminar: ' + departamento+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarDepartamento")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            getDepartamentos2();
            
        }
    }

	async function eliminarTarjeta(folio, tarjeta){
        
        
        if(window.confirm('¿Eliminar: ' + tarjeta+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarTarjeta")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			getTarjetas(folio); 
			setIsOpenTarjetas(false);
            notify((res.data).trim());
            
        }
    }

	
	async function actualizarNoTarjeta(folio, tarjeta){
        
        
        if(window.confirm('Actualizar: ' + tarjeta+'?')){ 
            let fd = new FormData() 
            fd.append("id", "actualizarNoTarjeta")
            fd.append("folio", folio)  
            fd.append("notarjeta", document.getElementById("nuevo-numero-t"+folio).value)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			getTarjetas(folio); 
			setIsOpenTarjetas(false);
            notify((res.data).trim());
            
        }
    }

	

	async function eliminarPersonaAutoriza(folio, persona){
        
        
        if(window.confirm('¿Eliminar: ' + persona+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarPersonaAutoriza")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			getAutorizaDepartamento(folio);
            
            notify((res.data).trim());
            
        }
		
    }
	async function eliminarPersonaAcceso(folio, persona){
        
        
        if(window.confirm('¿Eliminar: ' + persona+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarPersonaAcceso")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			getAccesoDepartamento(folio);
            
            notify((res.data).trim());
            
        }
		
    }

	async function eliminarPersonaNotificar(folio, persona){
        
        
        if(window.confirm('¿Eliminar: ' + persona+'?')){ 
            let fd = new FormData() 
            fd.append("id", "eliminarPersonaNotificar")
            fd.append("folio", folio)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			getNotificarDepartamento(folio);
            
            notify((res.data).trim());
            
        }
		
    }

	

	

	async function actualizarLimitePersonaAutoriza(folio, persona){
        
        
        if(window.confirm('¿Actualizar limite para: ' + persona+'?')){ 
            let fd = new FormData() 
            fd.append("id", "actualizarLimitePersonaAutoriza")
            fd.append("folio", folio)  
            fd.append("limite", document.getElementById("nuevo-limite-"+folio).value)  
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
			//closeModalAutoriza();
			setIsOpenAutoriza(false); 
			//getAutorizaDepartamento(folioDepartamentoSeleccionado);
            
            notify((res.data).trim());
            
        }
    }

	 /*
	async function CambiarRzon(e){ 
		e.preventDefault();  
		var select = document.getElementById("select").value;
		var id = "C5";  
		let fd = new FormData()  
		fd.append("id", id); 
		fd.append("select", select);  
		fd.append("userid", props.userid);  
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
        console.log(res.data[0]); 
		 
		if(res.data[0].res == "1"){  
			//setAcceso(false); 
			setUsuario(res.data[0].usuario); 
			setUserid(res.data[0].userid);
			setName(res.data[0].name);
			setDepartamento(res.data[0].departamento);
			setTipo(res.data[0].tipo);
			setRzonsocial(res.data[0].rzonsocial); 
			props.unmountMe(res.data[0].rzonsocial, res.data[0].dptoid);   
		}else{
            notify("No tiene acceso a esta razon social");
        }
		
	}*/
 
		return( 
            <div className='container'>
            <Nabvar titulo="Configuración" defaultColor={props.defaultColor} />
			<input id='input-cotizacion' type='file' class="input-hidden" onChange={()=> postFile2()}></input>

             <div className="row p-3">
                <h3>Empresas</h3>
            <div align="right" class="width-100">
                 <button className='btn btn-outline-success btn-sm' onClick={() => openModal()} class="btn btn-outline-success btn-sm width-100-px">Agregar</button>
				 </div>
            <div class="div-section1">
			<DataTableExtensions
          columns={columnsEmpresas}
          data={props.empresas}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columnsEmpresas}
					data={props.empresas}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>
                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Razón social</th>
                        <th>RFC</th>
                        <th>Dirección</th>
                        <th>Telefono</th>
                        <th>Logo</th>  
                        <th>Actualizar</th>  
                        <th>Eliminar</th>  
                        
                    </tr>

                    {props.empresas.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.name}</td>
                        <td>{item.rfc}</td>
                        <td>{item.direccion}</td>
                        <td><input id={"telefonoempresa"+item.dptoid}defaultValue={item.telefono} class="width-100-px"/></td>
						{
							(item.logo != null) ?
							<>
							<td class="width-100-px" align="center"><a href={process.env.REACT_APP_API_URL_IMG+item.logo} target="_blank">Ver</a>
							<span>&nbsp; &nbsp; </span>
                            <FaTrash class="color-red" onClick={() => removerLogo(item.dptoid)}/></td>
							</>
							:
							<td><button  className='btn btn-outline-success btn-sm' onClick={() => addLogo(item.dptoid)}>Agregar</button></td>
						}
                        
                        <td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarDatosEmpresa(item.dptoid, item.name)}>Actualizar</button></td>
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => eliminarEmpresa(item.dptoid, item.name)}>Eliminar</button></td>
                      
                    </tr>
                    ))}	
              
                </table> 
            </div>
			<h3>Logo</h3>
			<br></br>
			<div  class="width-100" align="center">
			<img src={process.env.REACT_APP_API_URL_DEFAULT +props.defaultLogo} style={{width:'500px'}}></img><br></br>
			<button className='btn btn-outline-danger btn-sm'>Quitar</button> 
            </div>
			<br></br>
			<h3>Color</h3>
			<br></br>
			<div class="width-100" align="center">


			<br></br>
			<input type="color" style={{width: '100%', height:'30px'}} defaultValue={props.defaultColor} id="defaultColor"></input>
			<button className='btn btn-outline-success btn-sm' onClick={() => actualizarColor()}>Actualizar</button> 
            </div>
			<br></br>
			<h3>Bancos</h3>
			<div align="right" class="width-100">
			
                 <button className='btn btn-outline-success btn-sm' onClick={() => openModalBanco()} class="btn btn-outline-success btn-sm width-100-px">Agregar</button>
				 </div>

			<div class="div-section1">
			<DataTableExtensions
          columns={columnsBancos}
          data={props.bancos}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columnsBancos}
					data={props.bancos}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>
                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Folio</th>
                        <th>Banco</th>  
						<th>Tarjetas</th> 
                        <th>Actualizar</th>  
                        <th>Eliminar</th> 
                        
                    </tr>

                    {props.bancos.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.folio}</td>   
                        <td><input id={"nombrebanco"+item.folio} defaultValue={item.nombrebanco}/></td> 
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalTarjetas(item.nombrebanco, item.folio)}>Ver / agregar</button></td>
                        <td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarNombreBanco(item.folio, item.nombrebanco)}>Actualizar</button></td>
						<td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarBanco(item.folio, item.nombrebanco)}>Eliminar</button></td>
                      
                    </tr>
                    ))}	
              
                </table> 
            </div>

			<br></br>
			<br></br>
			<h3>Departamentos</h3>
			<div align="right" class="width-100">
                 <button className='btn btn-outline-success btn-sm' onClick={() => openModalDepartamento()} class="btn btn-outline-success btn-sm width-100-px">Agregar</button>
			</div>
			<div class="div-section1">
			<DataTableExtensions
          columns={columnsDepartamentos}
          data={dptos2}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columnsDepartamentos}
					data={dptos2}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>
                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Folio</th>
                        <th>Departamento</th>
                        <th>Encargado (Realiza)</th>
                        <th>Encargado (Revisa)</th>
                        <th>Actualizar</th>
                        <th>Autoriza</th>
                        <th>Acceso</th>
                        <th>Eliminar</th>
                    </tr>

                    {dptos2.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.dptoid}</td>   
                        <td> <input class="width-100" id={"nuevonombredepartamento"+item.dptoid} defaultValue={item.name}></input></td> 
						<td><select id={"slc-realiza-"+item.dptoid} class="select-req-1">
							{(item.useridrealiza != null) ? 
							<option value={item.useridrealiza}>{item.nombrerealiza}</option>
							:
							<></>
							}	
						
											{ props.usuarios.map(item => ( 
											<option value={item.userid}>{item.name}</option>    
											))}  
										</select> 	
						</td> 
						<td>
						<select id={"slc-encargado-"+item.dptoid} class="select-req-1">
							{(item.useridencargado != null) ? 
							<option value={item.useridencargado}>{item.nombreencargado}</option>
							:
							<></>
							}	
						
											{ props.usuarios.map(item => ( 
											<option value={item.userid}> {item.name}</option>    
											))}  
										</select> 	</td> 
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarDatosDepartamento(item.dptoid, item.name)}>Actualizar</button></td>
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalAutoriza(item.name, item.dptoid)}>Ver / agregar</button></td>
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalAccesoaDpto(item.name, item.dptoid)}>Ver / agregar</button></td>
						<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarDepartamento(item.dptoid, item.name)}>Eliminar</button></td>
                    </tr>
                    ))}	
              
                </table> 
            </div>


			<br></br>
			<br></br>
			<h3>Categorías</h3>
			<div align="right" class="width-100">
			
                 <button className='btn btn-outline-success btn-sm' onClick={() => openModalCategoria()} class="btn btn-outline-success btn-sm width-100-px">Agregar</button>
				 </div>

			<div class="div-section1">
				<DataTableExtensions
          columns={columnsCategorias}
          data={props.categorias}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columnsCategorias}
					data={props.categorias}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>
                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Folio</th>
                        <th>Categoría</th>
                        <th>Actualizar</th>
                        <th>Eliminar</th>
                    </tr>

                    {props.categorias.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.id}</td>   
                        <td> <input class="width-100" id={"actualizarCategoria"+item.id} defaultValue={item.categoria}></input></td> 
						<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarCategoria(item.id)}>Actualizar</button></td>
 						<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarCategoria(item.id)}>Eliminar</button></td>
                    </tr>
                    ))}	
              
                </table> 
            </div>


			<br></br>
			<br></br>
			<h3>Unidades</h3>
			<div align="right" class="width-100">
			{/* agregar nueva unidad */}
                 <button  className='btn btn-outline-success btn-sm' onClick={() => openModalUnidad()} class="btn btn-outline-success btn-sm width-100-px">Agregar</button>
				 </div>

			<div class="div-section1">
				<DataTableExtensions
          columns={columnsUnidades}
          data={props.unidades}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columnsUnidades}
					data={props.unidades}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>

                <table id="productstable"  class="width-100" hidden> 
                    <tr>
                        <th>Folio</th>
                        <th>Unidad</th>
                        <th>Actualizar</th>
                        <th>Eliminar</th>
                    </tr>

                    {props.unidades.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.id}</td>   
                        <td> <input class="width-100" id={"actualizarUnidad"+item.id} defaultValue={item.unidad}></input></td> 
						<td><button   className='btn btn-outline-success btn-sm' onClick={() => actualizarUnidad(item.id)}>Actualizar</button></td>
 						<td><button  className='btn btn-outline-success btn-sm'    onClick={() => eliminarUnidad(item.id)}>Eliminar</button></td>
                    </tr>
                    ))}	
              
                </table> 
            </div>

            
            </div>

			
			
			<Modal 
			isOpen={modalIsOpen}  
			onRequestClose={closeModal}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Agregar Empresa</label>
       		 <br></br> 
       		 <br></br> 
			 <label>Razón social:</label><br></br> 
			 <input id="rzonsocial" class="width-100"/><br></br> 
			 <label>RFC:</label><br></br> 
			 <input id="rfc" class="width-100"/><br></br> 
			 <label>Dirección:</label><br></br> 
			 <input id="direccion" class="width-100"/><br></br> 
			 <label>Telefono:</label><br></br> 
			 <input id="telefono" class="width-100"/><br></br><br></br>
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarEmpresa()}>Guardar</button> 
			</div>  
			</Modal>
			 

			<Modal 
			isOpen={modalIsOpenBanco}  
			onRequestClose={closeModalBanco}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Agregar Banco</label>
       		 <br></br> 
       		 <br></br> 
			 <label>Banco:</label><br></br> 
			 <input id="nombrebanco" class="width-100"/><br></br> <br></br> 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarBanco()}>Guardar</button> 
			</div>  
			</Modal>

			<Modal 
			isOpen={modalIsOpenDepartamento}  
			onRequestClose={closeModalDepartamento}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Agregar Departamento</label>
       		 <br></br> 
       		 <br></br> 
			 <label>Departamento:</label><br></br> 
			 <input id="nombredepartamento" class="width-100"/><br></br> <br></br> 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarDepartamento()}>Guardar</button> 
			</div>  
			</Modal>
			<Modal 
			isOpen={modalIsOpenCategoria}  
			onRequestClose={closeModalCategoria}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Agregar Categoría</label>
       		 <br></br> 
       		 <br></br> 
			 <label>Categoría:</label><br></br> 
			 <input id="nombrecategoria" class="width-100"/><br></br> <br></br> 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarCategoria()}>Guardar</button> 
			</div>  
			</Modal>

			<Modal 
			isOpen={modalIsOpenUnidad}  
			onRequestClose={closeModalUnidad}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Agregar Unidad</label>
       		 <br></br> 
       		 <br></br> 
			 <label>Unidad:</label><br></br> 
			 <input id="nombreunidad" class="width-100"/><br></br> <br></br> 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarUnidad()}>Guardar</button> 
			</div>  
			</Modal>

			<Modal 
			isOpen={modalIsOpenTarjetas}  
			onRequestClose={closeModalTarjetas}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Tarjetas</label><span>&nbsp; </span>
			<label  class="label-1">{bancoSeleccionado}</label>
       		 <br></br> 
       		 <br></br> 
			 <table>
				<tr>
					<th>Folio</th>
					<th>Número de tarjeta</th>
					<th>Actualizar</th>
					<th>Eliminar</th>
				</tr>
				 
				{tarjetas.map(item => ( 
                    <tr class="font-14"> 
                        <td>{item.folio}</td>  
                        <td><input defaultValue={item.notarjeta} id={"nuevo-numero-t"+item.folio}></input></td>  
                        <td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarNoTarjeta(item.folio, item.notarjeta)}>Actualizar</button></td>  
                        <td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarTarjeta(item.folio, item.notarjeta)}>Eliminar</button></td>  
                    </tr>
                    ))}	
			 </table>
			 <label>Nueva tarjeta:</label><br></br> 
			 <input id="numerotarjeta" class="width-100"/><br></br> <br></br> 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => guardarTarjeta()}>Guardar</button> 
			</div>  
			</Modal>

			<Modal 
			isOpen={modalIsOpenAutoriza}  
			onRequestClose={closeModalAutoriza}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Autoriza</label><span>&nbsp; </span><br></br>
			<label  style={{color:'black', fontSize:'20px'}}>Departamento: {departamentoSeleccionado}</label>
       		 <br></br>  
			 <table>
				<tr>
					<th>Folio</th>
					<th>Nombre</th>
					<th>Limite</th>
					<th>Actualizar</th>
					<th>Eliminar</th>
				</tr>
				{ departamentoAutoriza.map(item => ( 
				<tr>
					<td>{item.userid}</td>
					<td>{item.name}</td>
					<td><input id={"nuevo-limite-"+item.folio} defaultValue={item.limite}></input></td>
					<td><button  className='btn btn-outline-success btn-sm' onClick={() => actualizarLimitePersonaAutoriza(item.folio, item.name)}>Actualizar</button></td>  
                    <td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarPersonaAutoriza(item.folio, item.name)}>Eliminar</button></td>  
                  
				</tr>
				))}  
				 
				 
			 </table>
			 <label>Agregar:</label><br></br> 
			 <select id={"slc-agg-autoriza"} class="select-req-1">
							 
				{ props.usuarios.map(item => ( 
				<option value={item.userid}> {item.name}</option>    
				))}  
			</select> 	 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => addAutorizaDepartamento()}>Agregar</button> 
			</div>  
			</Modal>

			<Modal 
			isOpen={modalIsOpenAccesoaDpto}  
			onRequestClose={closeModalAcceso}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Acceso al departamento</label><span>&nbsp; </span><br></br>
			<label  style={{color:'black', fontSize:'20px'}}>Departamento: {departamentoSeleccionado}</label>
       		 <br></br>  
			 <table>
				<tr>
					<th>Folio</th>
					<th>Nombre</th> 
					<th>Eliminar</th>
				</tr>
				{ departamentoAccesos.map(item => ( 
				<tr>
					<td>{item.userid}</td>
					<td>{item.name}</td>
					<td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarPersonaAcceso(item.folio, item.name)}>Eliminar</button></td>  
                  
				</tr>
				))}  
				 
				 
			 </table>
			 <label>Agregar:</label><br></br> 
			 <select id={"slc-agg-acceso"} class="select-req-1">
							 
				{ props.usuarios.map(item => ( 
				<option value={item.userid}> {item.name}</option>    
				))}  
			</select> 	 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => addAccesoDepartamento()}>Agregar</button> 
			</div>  
			</Modal>


			<Modal 
			isOpen={modalIsOpenNotificarDpto}  
			onRequestClose={closeModalNotificar}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Notificar</label><span>&nbsp; </span><br></br>
			<label  style={{color:'black', fontSize:'20px'}}>Departamento: {departamentoSeleccionado}</label>
       		 <br></br>  
			 <table>
				<tr>
					<th>Folio</th>
					<th>Nombre</th> 
					<th>Eliminar</th>
				</tr>
				{ departamentoNotificar.map(item => ( 
				<tr>
					<td>{item.userid}</td>
					<td>{item.name}</td>
					<td><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarPersonaNotificar(item.folio, item.name)}>Eliminar</button></td>  
                  
				</tr>
				))}  
				 
				 
			 </table>
			 <label>Agregar:</label><br></br> 
			 <select id={"slc-agg-notificar"} class="select-req-1">
							 
				{ props.usuarios.map(item => ( 
				<option value={item.userid}> {item.name}</option>    
				))}  
			</select> 	 	
			 
			 <button className='btn btn-outline-success btn-sm'  onClick={() => addNotificarDepartamento()}>Agregar</button> 
			</div>  
			</Modal>

			




			<ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
			<Toast/>    
 		

			
        </div>
        ); 
		 
	
}

export default Config;

